<template>
  <!-- 发票详情 -->
  <div class="invoiceDetails page-info-content">
    <Details-page
      :detail-obj="detailObj"
      :details-item-arr="detailsItemArr"
    />
    <div class="cancelButton">
      <el-button size="small" @click="$router.go(-1)">
        取消
      </el-button>
    </div>
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { invoiceIssureInvoiceDetail } from '@/http/custApi/financialManage'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailObj: {},
      detailsItemArr: [
        { label: '业务编码', value: 'busiNo' },
        { label: '发票代码', value: 'invoiceCode' },
        { label: '发票号码', value: 'invoiceNo' },
        { label: '发票金额', money: 'invoiceAmt' },
        { label: '发票抬头名称', value: 'invoiceTitleName' },
        { label: '社会信用代码', value: 'invoiceUnicode' },
        { label: '开户行账号', value: 'invoiceBankCode' },
        { label: '开户行名称', value: 'invoiceBankName' },
        { label: '发票类型', value: 'invoiceType', child: [{ val: '增值税普通发票', id: '00' }, { val: '增值税专用发票', id: '01' }] },
        { label: '接收方式', value: 'receiveType', child: [{ val: '电子发票', id: '00' }, { val: '纸质发票', id: '01' }] },
        { label: '发票状态', value: 'state', child: [{ val: '开票中', id: '01' }, { val: '已开票', id: '02' }] },
        { label: '开票方会员名称', value: 'openCmpName' },
        { label: '发票开据日期', value: 'openTime' },
        { label: '快递单号', value: 'courierNumber' },
        { label: '收件地址', value: 'recAddr' },
        { label: '电子邮箱', value: 'recMail' },
        { label: '联系人姓名', value: 'recName' },
        { label: '联系人电话', value: 'recTel' },
        { label: '发票附件', value: 'fdfsName', link: 'fdfsUrl' }
      ]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      invoiceIssureInvoiceDetail({ id: this.$route.query.id }, res => {
        this.detailObj = { ...res.data }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.invoiceDetails {
  .cancelButton{
    text-align: center;
    margin-top: 16px;
  }
  .demo-info {
    padding: 32px;
    font-size: 14px;
    color: #606266;
  }

  /deep/ .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
    line-height: 2.5;
  }
  .operation {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
